import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../../components/Layout/Layout"
import { Helmet } from "react-helmet"
import { renderTags } from "../../utilities/renderTags"
import Template from "../../components/Template/Template"
import PerimeterForm from "../../components/PerimeterForm/PerimeterForm"
import { LOCALES } from "../../utilities/utilities"
import { useScrollTracking } from "../../analytics/useScrollTracking"
import { usePageTracking } from "../../analytics/usePageTracking"
import getPageData from "../../analytics/getPageData"
export const query = graphql`
  query {
    template: contentfulTemplate(
      id: { eq: "d3e32b8e-b195-516f-a8ed-e5d828350375" }
    ) {
      ...templateFields
    }
    site {
      siteMetadata {
        defaultLocale
      }
    }
  }
`
const PerimetreAgicap = props => {
  useScrollTracking()
  usePageTracking()
  const locale = LOCALES.IT
  const trackingData = getPageData(
    locale,
    {
      pagePerimeter: "landing_page",
      pagePublication: "",
      pageFeature: "account_payable"
    },
    "lp-payer-vos-factures",
    props.path
  )
  const {
    template,
    site: {
      siteMetadata: { defaultLocale }
    }
  } = props.data

  const pageData = {
    hubspotForm: {
      name: "",
      formId: "42be366a-12b6-412b-b09d-631a862f5af2",
      portalId: "2856475",
      eventAction: "",
      eventLabel: ""
    },
    formTitle: "Perimetro Agicap"
  }
  return (
    <Layout>
      <Helmet>
        <title>Perimetro Agicap</title>
        <script type="text/javascript">{`${trackingData}`}</script>
        {template &&
          template.headTags &&
          renderTags(locale, defaultLocale, template.headTags)}
      </Helmet>
      <Template {...template} defaultLocale={defaultLocale} locale={locale}>
        <PerimeterForm
          {...pageData}
          locale={locale}
          defaultLocale={defaultLocale}
        />
      </Template>
    </Layout>
  )
}

PerimetreAgicap.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}

export default PerimetreAgicap
